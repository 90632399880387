import { FileText, Edit, Bell, Mail, Baby, Award, Banknote } from "lucide-react";

const icons = {
  attestation: FileText,
  modifications: Edit,
  notifications: Bell,
  verifications: Mail,
  registrations: Baby,
  reissuance: Award,
  total: Banknote,
};

export default function ChargeCard({
  title,
  icon,
  countNaira,
  amountNaira,
  countUSD,
  amountUSD,
}) {
  const Icon = icons[icon];

  return (
    <div className="p-3 border-round shadow-2">
      <div className="flex align-items-center p-0 no-gutter gap-2">
        <Icon className="bg-green-600 p-1 border-circle text-white" />
        <h3
          style={{ color: "#00923F" }}
          className="block text-sm font-semibold"
        >
          {title}
        </h3>
      </div>
      <div className="">
        <div className="text-sm flex gap-3 align-items-center ">
          <span className="font-normal w-6">Count(Naira)</span>
          <span className="font-semibold w-6">{countNaira ?? "0"}</span>
        </div>
        <div className=" text-sm flex gap-3 align-items-center">
          <span className="font-normal w-6">Naira</span>
          <span className="font-semibold w-6">
            ₦
            {amountNaira
              ? amountNaira.toLocaleString("en-NG", {
                  minimumFractionDigits: 2,
                })
              : "0"}
          </span>
        </div>
        <br />
        <div className=" text-sm flex gap-3  align-items-center">
          <span className="font-normal w-6">Count(USD)</span>
          <span className="font-semibold w-6">
            {countUSD ? countUSD.toLocaleString() : "0"}
          </span>
        </div>
        <div className="text-sm flex gap-3 align-items-center">
          <span className="font-normal w-6">US Dollar</span>
          <span className="font-semibold w-6">
            $
            {amountUSD
              ? amountUSD.toLocaleString("en-US", { minimumFractionDigits: 2 })
              : "0"}
          </span>
        </div>
      </div>
    </div>
  );
}
