import { useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { Outlet, Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/72.png";
import Avatar from "../assets/images/avatar.png";
import "./home.css";
import { logout } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Permissions, Roles } from "../helpers/constants";
import useModuleAccess from "../helpers/hooks/useModuleAccess";
import Swal from "sweetalert2";
import Menu from "../components/StateCommissioner/Menu/Menu";
import {
  AccessList,
  DcrStateAccessList,
  RegistrarAccessList,
} from "../helpers/roles.helper";
import { addSpacesToWords, mapRoleNameById } from "../utils";
import { version } from "../../package.json"
const Homez = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { userInfo: user } = useSelector((state) => state.user);
  const { checkModuleAccess } = useModuleAccess();
  // useEffect(() => {
  //   if (user.Role_ID === Roles.TIU.toString()) {
  //     navigate("/birth/all-registrations");
  //   } else {
  //     navigate("/dashboard");
  //   }
  // }, []);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const dashboardButtonRef = useRef(null);
  const stateCommisonerButtonRef = useRef(null);
  const deathRegButtonRef = useRef(null);
  const birthRegButtonRef = useRef(null);
  const birthAttestationButtonRef = useRef(null);
  const modificationButtonRef = useRef(null);
  const verificationButtonRef = useRef(null);
  const hospitalMenuButtonRef = useRef(null);
  const otherAcessButtonRef = useRef(null);
  const stillBirthMenuButtonRef = useRef(null);
  const notificationButtonRef = useRef(null);

  const canSeeBirth = checkModuleAccess(Permissions.canSeeBirth);
  const canSeeBirthApproval = checkModuleAccess(
    Permissions.canSeeBirthApproval
  );
  const canSeeDeath = checkModuleAccess(Permissions.canSeeDeath);
  const canSeeBirthReissuance = checkModuleAccess(Permissions.canSeeBirthCertReissuance);
  const canSeeAttestReissuance = checkModuleAccess(Permissions.canSeeAttestReissuance);
  const canSeeDeathApproval = checkModuleAccess(
    Permissions.canSeeDeathApproval
  );
  const canSeeAttestation = checkModuleAccess(Permissions.canSeeAattestation);
  const canSeeAttestationApproval = checkModuleAccess(
    Permissions.canSeeAttestationApproval
  );
  const canSeeModification = checkModuleAccess(Permissions.canSeeModification);
  const canSeeBirthModificationApproval = checkModuleAccess(
    Permissions.canSeeBirthModificationApproval
  );
  const canSeeDeathModificationApproval = checkModuleAccess(
    Permissions.canSeeDeathModificationApproval
  );
  const canSeeAttestationModificationApproval = checkModuleAccess(
    Permissions.canSeeAttestationModificationApproval
  );
  const hasDcrStateAccess = checkModuleAccess(DcrStateAccessList);
  const hasRegistrarAccess = checkModuleAccess(RegistrarAccessList);
  const token = localStorage.getItem("userInfo-admin-token-v2");

  const handleLogout = () => {
    localStorage.removeItem("userInfo-admin-v2");
    localStorage.removeItem("userInfo-admin-token-v2");
    dispatch(logout());
    nav("/unauth");
  };

  const confirmLogout = async () => {
    const result = await Swal.fire({
      title: "Confirm",
      text: `Are you sure you want to logout?`,
      showCancelButton: true,
      confirmButtonColor: "#0b6916",
      confirmButtonText: "Sign Out",
    });
    if (result.value) {
      handleLogout();
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="min-h-screen max-w-full flex relative lg:static surface-ground">
      {/* Sidebar */}
      <div
        id="app-sidebar-1"
        className={`surface-section h-screen ${sidebarOpen ? "block" : "hidden"
          } lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-2 select-none c-shadow`}
      // style={{ maxWidth: "20%" }}
      >
        <div
          className="flex flex-column h-full"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="flex align-items-center px-5 py-3 flex-shrink-0"
            style={{ backgroundColor: "white" }}
          >
            <Link
              to="/"
              className="flex flex-column align-items-center cursor-pointer gap-2"
            >
              <img src={Logo} alt="hyper-300" height="45" />
              <center className="text-sm uppercase text-green-500 font-bold">
                VITAL REG Management System
              </center>
              <small>v {version}</small>
            </Link>
          </div>
          <div
            className="hidden lg:flex align-items-center px-5 flex-shrink-0 border-bottom-1 border-top-1 border-200 "
            style={{ height: "100px", backgroundColor: "white" }}
          >
            <div className="flex align-items-center cursor-pointer gap-4">
              <img
                src={Avatar}
                alt="hyper-300"
                height="45"
                style={{
                  borderRadius: "50%",
                }}
              />
              <div className="flex flex-column ">
                <span className="text-sm text-900 uppercase font-bold -mb-1">
                  {user?.UserName}
                </span>
                <span className="text-sm font-medium -mb-1">
                  {addSpacesToWords(mapRoleNameById(+user.roles.id, user?.State_ID))}
                </span>

                <span className="text-xs font-medium">
                  {user?.State?.State_Name}
                </span>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto scrollable-container text-sm mt-3">
            {+user.Role_ID === Roles.StateCommisioner ? (
              <Menu />
            ) : (
              <>
                <ul className="list-none p-3 m-0 ">
                  {![Roles.TIU].includes(+user?.Role_ID) && (
                    <li className="border-bottom-1 border-200">
                      <Link
                        to="/dashboard"
                        className="flex align-items-center cursor-pointer p-3 transition-duration-150  w-full text-dark"
                      >
                        <i className="pi pi-home mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark font-medium">
                          Dashboard
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  )}

                  {(canSeeBirth || canSeeBirthApproval) && (
                    <li className="border-bottom-1 border-200">
                      <StyleClass
                        nodeRef={birthRegButtonRef}
                        selector="@next"
                        enterClassName="hidden"
                        enterActiveClassName="slidedown"
                        leaveToClassName="hidden"
                        leaveActiveClassName="slideup"
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          ref={birthRegButtonRef}
                          className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                        >
                          <i className="pi pi-user mr-2 text-green-500 font-bold"></i>
                          <span className="text-dark font-medium">
                            Birth registrations
                          </span>
                          <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                          <Ripple />
                        </a>
                      </StyleClass>
                      <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        {canSeeBirth && (
                          <>
                            <li>
                              <Link
                                to="/birth"
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  Statistics
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  +user.Role_ID === Roles.SubAdmin &&
                                    user.State_ID
                                    ? `/birth/lga/${user.State_ID}`
                                    : "/birth/registrations"
                                }
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  Registrations
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                          </>
                        )}

                        {canSeeBirthApproval && (
                          <>
                            <li>
                              <Link
                                to="/birth/all-registrations"
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  All Registrations
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                          </>
                        )}

                        {canSeeBirthReissuance&& (
                          <>
                            <li>
                              <Link
                                to="/birth-cert-reissuance-list"
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  Certificate Reissuance
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </li>
                  )}

                  {(canSeeDeath || canSeeDeathApproval) && (
                    <li className="border-bottom-1 border-200">
                      <StyleClass
                        nodeRef={deathRegButtonRef}
                        selector="@next"
                        enterClassName="hidden"
                        enterActiveClassName="slidedown"
                        leaveToClassName="hidden"
                        leaveActiveClassName="slideup"
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          ref={deathRegButtonRef}
                          className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                      transition-duration-150 transition-colors w-full"
                        >
                          <i className="pi pi-file-excel mr-2 text-green-500 font-bold"></i>
                          <span className="text-dark font-medium">
                            Death registrations
                          </span>
                          <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                          <Ripple />
                        </a>
                      </StyleClass>
                      <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        {canSeeDeath && (
                          <>
                            <li>
                              <Link
                                to="/death"
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  Statistics
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  +user.Role_ID === Roles.SubAdmin &&
                                    user.State_ID
                                    ? `/death/lga/${user.State_ID}`
                                    : "/death/registrations"
                                }
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  Registrations
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                          </>
                        )}

                        {canSeeDeathApproval && (
                          <>
                            <li>
                              <Link
                                to="/death/all-registrations"
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  All Registrations
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </li>
                  )}

                  {checkModuleAccess(Permissions.canSeeStillbirth) && (
                    <li className="border-bottom-1 border-200">
                      <StyleClass
                        nodeRef={stillBirthMenuButtonRef}
                        selector="@next"
                        enterClassName="hidden"
                        enterActiveClassName="slidedown"
                        leaveToClassName="hidden"
                        leaveActiveClassName="slideup"
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          ref={stillBirthMenuButtonRef}
                          className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                        >
                          <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                          <span className="text-dark font-medium">
                            Still Birth
                          </span>
                          <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                          <Ripple />
                        </a>
                      </StyleClass>
                      <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        <li>
                          <Link
                            to="/still"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Statistics
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              +user.Role_ID === Roles.SubAdmin && user.State_ID
                                ? `/still/lga/${user.State_ID}`
                                : "/still/registrations"
                            }
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}

                  {(canSeeAttestation || canSeeAttestationApproval) && (
                    <li className="border-bottom-1 border-200">
                      <StyleClass
                        nodeRef={birthAttestationButtonRef}
                        selector="@next"
                        enterClassName="hidden"
                        enterActiveClassName="slidedown"
                        leaveToClassName="hidden"
                        leaveActiveClassName="slideup"
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          ref={birthAttestationButtonRef}
                          className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                        >
                          <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                          <span className="text-dark font-medium">
                            Birth Attestations
                          </span>
                          <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                          <Ripple />
                        </a>
                      </StyleClass>
                      <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        {canSeeAttestation && (
                          <>
                            <li>
                              <Link
                                to="/attestation"
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  Statistics
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={
                                  +user.Role_ID === Roles.SubAdmin &&
                                    user.State_ID
                                    ? `/attestation/lga/${user.State_ID}`
                                    : "/attestation/registrations"
                                }
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  Registrations
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                          </>
                        )}

                        {canSeeAttestationApproval && (
                          <>
                            <li>
                              <Link
                                to="/attestation/all-registrations"
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  All Registrations
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                          </>
                        )}
                        {canSeeAttestReissuance&& (
                          <>
                            <li>
                              <Link
                                to="/birth-attestation-cert-reissuance-list"
                                className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                              >
                                <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                                <span className="font-medium text-dark">
                                  Certificate Reissuance
                                </span>
                                <Ripple />
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </li>
                  )}

                  {(canSeeModification ||
                    canSeeAttestationModificationApproval ||
                    canSeeBirthModificationApproval ||
                    canSeeDeathModificationApproval) && (
                      <li className="border-bottom-1 border-200">
                        <StyleClass
                          nodeRef={modificationButtonRef}
                          selector="@next"
                          enterClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          {/* eslint-disable-next-line */}
                          <a
                            ref={modificationButtonRef}
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-user-edit mr-2 text-green-500 font-bold"></i>
                            <span className="text-dark font-medium">
                              Modifications
                            </span>
                            <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                            <Ripple />
                          </a>
                        </StyleClass>

                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                          {canSeeModification && (
                            <>
                              {" "}
                              <li>
                                <Link
                                  to="/modify/birth/registrations"
                                  className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                                >
                                  <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                  <span className="font-medium text-dark">
                                    Birth registrations
                                  </span>
                                  <Ripple />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/modify/death/registrations"
                                  className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                                >
                                  <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                  <span className="font-medium text-dark">
                                    Death Registrations
                                  </span>
                                  <Ripple />
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/modify/attest/registrations"
                                  className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                                >
                                  <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                  <span className="font-medium text-dark">
                                    Birth attestations
                                  </span>
                                  <Ripple />
                                </Link>
                              </li>
                            </>
                          )}
                          {canSeeBirthModificationApproval && (
                            <>
                              <li>
                                <Link
                                  to="birth-modifications/all-registrations"
                                  className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                                >
                                  <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                                  <span className="font-medium text-dark">
                                    All Birth modifications
                                  </span>
                                  <Ripple />
                                </Link>
                              </li>
                            </>
                          )}
                          {canSeeDeathModificationApproval && (
                            <>
                              <li>
                                <Link
                                  to="death-modifications/all-registrations"
                                  className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                                >
                                  <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                                  <span className="font-medium text-dark">
                                    All Death modifications
                                  </span>
                                  <Ripple />
                                </Link>
                              </li>
                            </>
                          )}
                          {canSeeAttestationModificationApproval && (
                            <>
                              <li>
                                <Link
                                  to="attestation-modifications/all-registrations"
                                  className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                                >
                                  <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                                  <span className="font-medium text-dark">
                                    All Attestation modifications
                                  </span>
                                  <Ripple />
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </li>
                    )}
                  {checkModuleAccess([
                    Permissions.canSeeBirthNotification,
                    Permissions.canSeeAttestationWithNoNIN,
                  ]) && (
                      <li className="border-bottom-1 border-200">
                        <StyleClass
                          nodeRef={notificationButtonRef}
                          selector="@next"
                          enterClassName="hidden"
                          enterActiveClassName="slidedown"
                          leaveToClassName="hidden"
                          leaveActiveClassName="slideup"
                        >
                          {/* eslint-disable-next-line */}
                          <a
                            ref={notificationButtonRef}
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-bell mr-2 text-green-500 font-bold"></i>
                            <span className="text-dark font-medium">
                              Notifications
                            </span>
                            <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                            <Ripple />
                          </a>
                        </StyleClass>

                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                          {checkModuleAccess(
                            Permissions.canSeeBirthNotification
                          ) && (
                              <>
                                <li>
                                  <Link
                                    to="birth-notifications/all-registrations"
                                    className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                                  >
                                    <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                    <span className="font-medium text-dark">
                                      Birth Notifications
                                    </span>
                                    <Ripple />
                                  </Link>
                                </li>
                              </>
                            )}
                          {checkModuleAccess(
                            Permissions.canSeeAttestationWithNoNIN
                          ) && (
                              <>
                                <li>
                                  <Link
                                    to="attestation-without-nin/all-registrations"
                                    className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                                  >
                                    <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                                    <span className="font-medium text-dark">
                                      Attestation without NIN
                                    </span>
                                    <Ripple />
                                  </Link>
                                </li>
                              </>
                            )}
                        </ul>
                      </li>
                    )}

                  {checkModuleAccess(Permissions.canSeeVerification) && (
                    <li className="border-bottom-1 border-200">
                      <StyleClass
                        nodeRef={verificationButtonRef}
                        selector="@next"
                        enterClassName="hidden"
                        enterActiveClassName="slidedown"
                        leaveToClassName="hidden"
                        leaveActiveClassName="slideup"
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          ref={verificationButtonRef}
                          className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                        >
                          <i className="pi pi-check mr-2 text-green-500 font-bold"></i>
                          <span className="text-dark font-medium">
                            Verification
                          </span>
                          <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                          <Ripple />
                        </a>
                      </StyleClass>
                      <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        <li>
                          <Link
                            to="/all-organizations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Organizations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/pending-verification"
                            className="p-ripple flex align-items-center cursor-pointer p-3 border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Verifications{" "}
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}

                  {checkModuleAccess(Permissions.canSeeHospital) && (
                    <li className="border-bottom-1 border-200">
                      <StyleClass
                        nodeRef={hospitalMenuButtonRef}
                        selector="@next"
                        enterClassName="hidden"
                        enterActiveClassName="slidedown"
                        leaveToClassName="hidden"
                        leaveActiveClassName="slideup"
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          ref={hospitalMenuButtonRef}
                          className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                        >
                          <i className="pi pi-plus mr-2 text-green-500 font-bold"></i>
                          <span className="font-medium text-dark font-medium">
                            Hospitals
                          </span>
                          <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                          <Ripple />
                        </a>
                      </StyleClass>
                      <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        <li>
                          <Link
                            to="/hospital/public"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Statistics
                            </span>
                            <Ripple />
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/hospital/pending"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Hospital list
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}

                  {/* {checkModuleAccess(Permissions.canManageUser) && ( */}
                  {/* <li className="border-bottom-1 border-200">
                <StyleClass
                  nodeRef={btnRef13}
                  selector="@next"
                  enterClassName="hidden"
                  enterActiveClassName="slidedown"
                  leaveToClassName="hidden"
                  leaveActiveClassName="slideup"
                >
=                  <a
                    ref={btnRef13}
                    className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                  >
                    <i className="pi pi-users mr-2 text-green-500 font-bold"></i>
                    <span className="font-medium text-dark font-medium">
                      User access
                    </span>
                    <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                    <Ripple />
                  </a>
                </StyleClass>
                <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                  <li>
                    <Link
                      to="/admins"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">Admins</span>
                      <Ripple />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/registrars-list"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">Registrars</span>
                      <Ripple />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dcr-list"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">DCR</span>
                      <Ripple />
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/directors"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">
                        State Directors
                      </span>
                      <Ripple />
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/adhoc-list"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">Adhoc</span>
                      <Ripple />
                    </Link>
                  </li>
                </ul>
              </li> */}
                  {/* )} */}
                  {checkModuleAccess(Permissions.canSeeTransactionReport) && (
                    <li className="border-bottom-1 border-200">
                      <Link
                        to="/transaction-report"
                        className="flex align-items-center cursor-pointer p-3 transition-duration-150  w-full text-dark"
                      >
                        {/* <a className="p-ripple flex align-items-center cursor-pointer p-3  
                transition-duration-150 transition-colors w-full "> */}
                        <i className="pi pi-file mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark font-medium">
                          Transaction report
                        </span>
                        <Ripple />
                        {/* </a> */}
                      </Link>
                    </li>
                  )}

                  {checkModuleAccess(Permissions.canManageUser) && (
                    <li className="border-bottom-1 border-200">
                      <Link
                        to="/user-access"
                        className="flex align-items-center cursor-pointer p-3 transition-duration-150  w-full text-dark"
                      >
                        <i className="pi pi-home mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark font-medium">
                          User Access
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  )}

                  {AccessList.includes(+user.Role_ID) && (
                    <li className="border-bottom-1 border-200">
                      <StyleClass
                        nodeRef={otherAcessButtonRef}
                        selector="@next"
                        enterClassName="hidden"
                        enterActiveClassName="slidedown"
                        leaveToClassName="hidden"
                        leaveActiveClassName="slideup"
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          ref={otherAcessButtonRef}
                          className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                        >
                          <i className="pi pi-plus mr-2 text-green-500 font-bold"></i>
                          <span className="font-medium text-dark font-medium">
                            Other Access
                          </span>
                          <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                          <Ripple />
                        </a>
                      </StyleClass>
                      <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        {hasDcrStateAccess && (
                          <li>
                            <Link
                              to={`${process.env.REACT_APP_DCR_URL}/login?t=${token}`}
                              target="_blank"
                              className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                            >
                              <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                              <span className="font-medium text-sm text-dark">
                                DCR/State Director
                              </span>
                              <Ripple />
                            </Link>
                          </li>
                        )}

                        {hasRegistrarAccess && (
                          <li>
                            <Link
                              to={`${process.env.REACT_APP_HOST}/login?t=${token}`}
                              target="_blank"
                              className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                            >
                              <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                              <span className="font-medium text-dark">
                                Registrar{" "}
                              </span>
                              <Ripple />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}

                  <li onClick={confirmLogout}>
                    {/* eslint-disable-next-line */}
                    <a
                      className="p-ripple flex align-items-center cursor-pointer p-3 border-round 
            transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-sign-out mr-2 text-green-500"></i>
                      {/* <span className="font-medium">Sign Out</span> */}
                      <span className="font-medium text-dark font-medium">
                        Sign Out
                      </span>
                      <Ripple />
                    </a>
                  </li>
                </ul>
              </>
            )}
          </div>

          {/* <div className="mt-auto mx-3">
            <ul className="list-none p-2 m-0 hidden">
              <li onClick={handleLogout}>
                <a
                  className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
            transition-duration-150 transition-colors w-full"
                >
                  <i className="pi pi-sign-out mr-2 text-green-500"></i>
                  <span className="font-medium text-green-500">Sign Out</span>
                  <Ripple />
                </a>
              </li>
            </ul>
            <StyleClass
              nodeRef={btnRef3}
              selector="@prev"
              enterClassName="hidden"
              enterActiveClassName="fadein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
            >
              <a
                ref={btnRef3}
                className="p-ripple my-3 px-3 py-2 flex align-items-center hover:bg-bluegray-900 border-round cursor-pointer text-bluegray-100 hover:text-bluegray-50
        transition-duration-150 transition-colors w-full"
              >
                <span className="font-medium uppercase text-green-500">
                  {user?.UserName}
                </span>

                <i className="pi pi-chevron-up ml-auto text-green-500"></i>
                <Ripple />
              </a>
            </StyleClass>
          </div> */}
        </div>
      </div>
      <div className="min-h-screen overflow-y-auto flex flex-column relative flex-auto">
        {+user.Role_ID !== Roles.StateCommisioner ? (
          <>
            <div
              className="flex justify-content-between align-items-center px-5  shadow-2 relative lg:sticky top-0 left-0 border-bottom-1 surface-border z-1"
              style={{ height: "63px", backgroundColor: "#0E7706" }}
            >
              <div className="flex">
                <p className="hidden lg:block text-white font-bold ">
                  Dashboard
                </p>
                <StyleClass
                  nodeRef={dashboardButtonRef}
                  selector="#app-sidebar-1"
                  enterClassName="hidden"
                  enterActiveClassName="fadeinleft"
                  leaveToClassName="hidden"
                  leaveActiveClassName="fadeoutleft"
                  hideOnOutsideClick
                >
                  {/* eslint-disable-next-line */}
                  <a
                    ref={dashboardButtonRef}
                    className="p-ripple cursor-pointer block lg:hidden text-white mr-3"
                  >
                    {/* <i className="pi pi-bars text-4xl"></i> */}
                    <Ripple />
                  </a>
                </StyleClass>
              </div>
              <StyleClass
                nodeRef={stateCommisonerButtonRef}
                selector="@next"
                enterClassName="hidden"
                enterActiveClassName="fadein"
                leaveToClassName="hidden"
                leaveActiveClassName="fadeout"
                hideOnOutsideClick
              >
                {/* eslint-disable-next-line */}
                <a
                  ref={stateCommisonerButtonRef}
                  className="p-ripple cursor-pointer block lg:hidden text-700"
                >
                  <i className="pi pi-ellipsis-v text-2xl"></i>
                  <Ripple />
                </a>
              </StyleClass>
            </div>
            <div className="p-5 overflow-y-auto flex flex-column flex-auto">
              <div className=" flex-auto">
                <Outlet />
              </div>
            </div>
          </>
        ) : (
          <div className="overflow-y-auto flex flex-column flex-auto">
            <div className=" flex-auto">
              <Outlet />
            </div>
          </div>
        )}
      </div>
      {/* Toggle button */}
      <button
        onClick={toggleSidebar}
        className="fixed bottom-4 right-4 z-5 bg-transparent  p-3 border-circle border-transparent lg:hidden flex align-items-center justify-content-center mt-1 ml-2"
        aria-label={sidebarOpen ? "Close Sidebar" : "Open Sidebar"}
      >
        <i className={`pi ${sidebarOpen ? 'pi-times text-black' : 'pi-bars text-white'} text-xl`} />
      </button>

    </div>
  );
};

export default Homez;
