import { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { VerificationsTypeFilterOptions } from "../helpers/constants";
import CardWidget from "../components/widgets/cards";
import FilterHeader from "../components/FilterHeader";
import {
  getAllVerificationsList,
  getAllVerificationStats,
} from "../redux/apiCalls";

const Verification = () => {
  const toast = useRef(null);
  const [statisticsLoading, setStatisticsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState(null);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(20);
  const [pagination, setPagination] = useState([]);
  const [filters, setFilters] = useState({
    self: "",
    type: "",
    search: "",
  });
  let pageIndexOffset = 0;
  const pageStartIndex = page > 1 ? (page - 1) * rows : 0;
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1); // PrimeReact uses 0-based page index
    setRows(event.rows);
    pageIndexOffset = event.first;
  };

  const [registrations, setRegistrations] = useState([]);
  const getStatistics = async () => {
    try {
      const data = await getAllVerificationStats();
      setStatistics(data);
      setStatisticsLoading(false);
    } catch (error) {
      console.error(error);
      setStatisticsLoading(false);
    }
  };
  const getRecords = async () => {
    setLoading(true);
    try {
      const data = await getAllVerificationsList({
        resultPerPage: rows,
        page,
        type: filters.type || null,
        search: filters.search || null,
      });
      setRegistrations(data?.result);
      setPagination(data?.pagination);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    getRecords();
  }, [filters, page, rows]);

  const stats = [
    {
      name: "Total Verifications",
      count: statistics?.total,
      color: "#FFDC27",
    },
  ];

  const SN = (rowData) => {
    const sn = registrations.findIndex((items) => items === rowData);

    return <p>{pageStartIndex + sn + 1 + pageIndexOffset}</p>;
  };

  const filterGroups = [
    {
      name: "type",
      label: "Type",
      type: "dropdown",
      options: VerificationsTypeFilterOptions,
      optionLabel: "name",
      optionValue: "id",
    },
    { name: "search", label: "Search", type: "search" },
  ];
  const handleFilterChange = (updatedFilters) => {
    setFilters(updatedFilters);
  };
  const renderHeader1 = () => {
    return (
      <div className="flex align-items-center	">
        <p className="capitalize w-4">
          {filters?.status === "" ? "All" : filters?.status ?? "All"}{" "}
          Verifications - {pagination?.total}
        </p>
        <div className="w-full">
          <FilterHeader
            filters={filterGroups}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>
    );
  };

  const header1 = renderHeader1();
  const dt = useRef(null);

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <div className=" mt-2">
        <div className="">
          <div className="mt-3 w-full m-0 grid gap-4 ">
            {stats.map((item, index) => {
              const { name, color, count } = item;
              return (
                <div className="col-5" key={index}>
                  <CardWidget
                    loading={statisticsLoading}
                    name={name}
                    color={color}
                    count={count}
                  />
                </div>
              );
            })}
          </div>

          <div className="card mt-6 p-3 ">
            <DataTable
              value={registrations}
              ref={dt}
              loading={loading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
              id="p-table"
            >
              <Column body={SN} header="S/N"></Column>
              <Column field="person_name" header="Name"></Column>
              <Column field="reference" header="Payment Reference"></Column>
              <Column header="Token" field="token"></Column>
              <Column
                field="organization_type"
                header="Organization Type"
              ></Column>
              <Column
                field="organization_name"
                header="Organization Name"
              ></Column>
              <Column field="organization_country" header="Country"></Column>
              {/* <Column field="" header="Action" body={statusBodyTemplate2} /> */}
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={first}
              rows={rows}
              totalRecords={pagination?.total}
              rowsPerPageOptions={[10, 20, 50, 100]}
              // page={page}
              onPageChange={onPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
