import { useEffect, useState, useRef } from "react";
import authFetch from "../axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { SerialNumberTemplate, exportExcel } from "../utils";
import useModuleAccess from "../helpers/hooks/useModuleAccess";
import { Permissions } from "../helpers/constants";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { useDebounce } from "../helpers/hooks/useDebounce";
import ChargeCard from "../components/widgets/charge-card";
const Transact = () => {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rez, setRez] = useState(null);
  const [tot, setTot] = useState(null);
  const [type, setType] = useState(null);
  const [status, setStatus] = useState("pending");
  const [currency, setCurrency] = useState("NGN");
  const [basicFirst, setBasicFirst] = useState(0);
  const refSubmit = useRef(null);
  const [datez, setDates] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [result_per_page, set_result_per_page] = useState(100);
  const { value: debouncedValue } = useDebounce(search, 1000);
  // eslint-disable-next-line
  const [basicRows, setBasicRows] = useState(20);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setPage(event.page + 1);
  };

  const handleClose = () => {
    refSubmit.current.hide(() => {
      console.log("hiddeen");
    });
  };
  const clearFilter1 = () => {
    // initFilters1();
    setSearch((prev) => {
      return "";
    });

    setType(null);
    setCurrency("NGN");
    setStatus("pending");
    setDates(null);
    setDateFrom(null);
    setDateTo(null);
  };

  const typeOption = [
    {
      Status_Name: "Organization Verification",
      Status_ID: "cert_verification_request",
    },
    {
      Status_Name: "Educational Institute Verification",
      Status_ID: "cvr_educational_institute",
    },
    {
      Status_Name: "Selfservice",
      Status_ID: "self_service_payment",
    },
    {
      Status_Name: "Birth Attestation",
      Status_ID: "attestation_payment",
    },
    {
      Status_Name: "Birth Notification",
      Status_ID: "birthnoti_payment",
    },
    {
      Status_Name: "Birth Notification Modification",
      Status_ID: "birthnoti_mod_payment",
    },
    {
      Status_Name: "Birth Attestation Modification",
      Status_ID: "attest_modification_payment",
    },
    {
      Status_Name: "Birth Registration Modification",
      Status_ID: "birth_modification_payment",
    },
    {
      Status_Name: "Birth Registration",
      Status_ID: "birth_registration_payment",
    },
    {
      Status_Name: "Marriage Registration",
      Status_ID: "marriage_registration_payment",
    },
    {
      Status_Name: "Migration Notification",
      Status_ID: "migration_registration_payment",
    },
    {
      Status_Name: "Divorce Notification",
      Status_ID: "divorce_registration_payment",
    },
    {
      Status_Name: "Death Registration Modification",
      Status_ID: "death_modification_payment",
    },
    {
      Status_Name: "Certificate reprint payment",
      Status_ID: "certificate_reprint_payment",
    },
    {
      Status_Name: "Certificate Reissuance",
      Status_ID: "cert_reissuance_payment",
    },
  ];
  const getTypeName = (statusId) => {
    const status = typeOption.find((item) => item.Status_ID === statusId);
    return status ? status.Status_Name : "";
  };
  const currencyOption = [
    {
      Status_Name: "Naira",
      Status_ID: "NGN",
    },
    {
      Status_Name: "Dollar",
      Status_ID: "USD",
    },
  ];
  const statusOption = [
    {
      Status_Name: "Pending",
      Status_ID: "pending",
    },
    {
      Status_Name: "Completed",
      Status_ID: "completed",
    },
    {
      Status_Name: "Failed",
      Status_ID: "failed",
    },
    {
      Status_Name: "Canceled",
      Status_ID: "canceled",
    },
  ];

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC", // Ensure UTC time zone
  };
  const statusBodyTemplate9 = (rowData) => {
    return (
      <p className={`btn btn-primary text-primary font-bold cursor-pointer`}>
        {new Date(rowData?.created_at).toLocaleString("en-GB", options)}
      </p>
    );
  };

  const handleDate = (e) => {
    let dates = e.target.value;
    setDates(dates);
    setDateFrom(moment(dates[0]).format("YYYY-MM-DD"));
    setDateTo(moment(dates[1]).format("YYYY-MM-DD"));
  };

  const renderHeader1 = () => {
    return (
      <div className=" hidden md:flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter1}
        />
        <Dropdown
          value={type}
          options={typeOption}
          onChange={(e) => setType(e.value)}
          placeholder="Select Type"
          optionLabel="Status_Name"
          optionValue="Status_ID"
        />
        <Dropdown
          value={currency}
          options={currencyOption}
          onChange={(e) => setCurrency(e.value)}
          placeholder="Select Currenncy"
          optionLabel="Status_Name"
          optionValue="Status_ID"
        />
        <Dropdown
          value={status}
          options={statusOption}
          onChange={(e) => setStatus(e.value)}
          placeholder="Select Status"
          optionLabel="Status_Name"
          optionValue="Status_ID"
        />
        <Calendar
          ref={refSubmit}
          value={datez}
          onChange={handleDate}
          maxDate={new Date()}
          selectionMode="range"
          readOnlyInput
          placeholder="date range"
          footerTemplate={(e) => (
            <div className="footer-calendar">
              <button onClick={handleClose}>Done</button>
            </div>
          )}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();
  const canListTransactionReport = useModuleAccess(
    Permissions.canListTransactionReport
  );
  const canViewTransactionReportCards = useModuleAccess(
    Permissions.canViewTransactionReportCards
  );
  const dt = useRef(null);
  useEffect(() => {
    const getRecords = async () => {
      setLoading(true);
      try {
        const res = await authFetch.get(`/revenue`, {
          params: {
            search: debouncedValue,
            result_per_page,
            page,
            type,
            currency,
            status,
            dateFrom,
            dateTo,
          },
        });
        setLoading(false);
        setLists(res.data.result);
        setTot(res.data.pagination.total);
      } catch (error) {
        setLoading(false);
        console.log(error);
        // toast.current.show({ severity: 'error', summary: 'Error', detail: `` });
      }
    };
    getRecords();
  }, [
    debouncedValue,
    page,
    type,
    currency,
    status,
    dateFrom,
    dateTo,
    // canListTransactionReport,
  ]);

  useEffect(() => {
    const getStatz = async () => {
      try {
        const statistics = await authFetch.get("/revenue/stats");
        console.log(statistics.data);
        setRez(statistics.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (canViewTransactionReportCards) getStatz();
  }, []);

  const { serials } = useTableSerial({
    data: lists,
    currentPage: page,
    pageSize: result_per_page,
  });

  return (
    <>
      <div className="grid my-3">
        {/* <div className="col-12  bg-green-700">
          <span className="text-0 font-bold text-sm">Total Registrations: {rez && rez.total_registration?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> 
          </div> */}
        {canViewTransactionReportCards && (
          <div className="grid">
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Birth Attestation"
                icon="attestation"
                countNaira={rez?.attestation?.countNGN}
                amountNaira={rez?.attestation?.ngn}
                countUSD={rez?.attestation?.countUSD}
                amountUSD={rez?.attestation?.usd}
              />{" "}
            </div>
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Birth Modification"
                icon="modifications"
                countNaira={rez?.modification?.countNGN}
                amountNaira={rez?.modification?.ngn}
                countUSD={rez?.modification?.countUSD}
                amountUSD={rez?.modification?.usd}
              />{" "}
            </div>
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Notifications"
                icon="notifications"
                countNaira={rez?.notification?.countNGN}
                amountNaira={rez?.notification?.ngn}
                countUSD={rez?.notification?.countUSD}
                amountUSD={rez?.notification?.usd}
              />{" "}
            </div>{" "}
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Verifications"
                icon="verifications"
                countNaira={rez?.verification?.countNGN}
                amountNaira={rez?.verification?.ngn}
                countUSD={rez?.verification?.countUSD}
                amountUSD={rez?.verification?.usd}
              />{" "}
            </div>{" "}
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Birth Registration"
                icon="registrations"
                countNaira={rez?.birth?.countNGN}
                amountNaira={rez?.birth?.ngn}
                countUSD={rez?.birth?.countUSD}
                amountUSD={rez?.birth?.usd}
              />{" "}
            </div>{" "}
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Reprint"
                icon="verifications"
                countNaira={rez?.reprint?.countNGN}
                amountNaira={rez?.reprint?.ngn}
                countUSD={rez?.reprint?.countUSD}
                amountUSD={rez?.reprint?.usd}
              />{" "}
            </div>
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Certificate Reissuance"
                icon="reissuance"
                countNaira={rez?.reissuance?.countNGN}
                amountNaira={rez?.reissuance?.ngn}
                countUSD={rez?.reissuance?.countUSD}
                amountUSD={rez?.reissuance?.usd}
              />{" "}
            </div>{" "}
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Total Charges"
                icon="total"
                countNaira={rez?.charges?.countNGN}
                amountNaira={rez?.charges?.ngn}
                countUSD={rez?.charges?.countUSD}
                amountUSD={rez?.charges?.usd}
              />{" "}
            </div>
            <div className="col-12 md:col-4 xl:col-3">
              <ChargeCard
                title="Refunded"
                icon="total"
                countNaira={rez?.refunded?.countNGN}
                amountNaira={rez?.refunded?.ngn}
                countUSD={rez?.refunded?.countUSD}
                amountUSD={rez?.refunded?.usd}
              />{" "}
            </div>
          </div>
        )}

        {canListTransactionReport && (
          <div className="col-12 lg:col-12 my-2">
            <div className="card border-round shadow-2 p-3 ">
              <div className="mb-3 flex align-items-center justify-content-between p-3">
                <span className="font-medium text-900">
                  {getTypeName(type)} Transactions List
                </span>
                <span className="font-medium text-900">
                  Total count:{tot}
                </span>
                <div className="flex align-items-center export-buttons">
                  <Button
                    type="button"
                    icon="pi pi-file-excel"
                    onClick={() => exportExcel("transactions")}
                    className="p-button-success mr-2"
                    data-pr-tooltip="XLS"
                  />
                </div>
              </div>
              <DataTable
                value={lists}
                ref={dt}
                loading={loading}
                stripedRows
                responsiveLayout="stack"
                header={header1}
              >
                <Column
                  field="id"
                  header="S/N"
                  body={(cell) => SerialNumberTemplate(cell, serials, lists)}
                ></Column>
                <Column field="email" header="Email"></Column>
                <Column field="amount" header="Amount"></Column>
                <Column field="reference" header="Reference"></Column>
                <Column field="isUsed" header="Used"></Column>
                <Column field="status" header="Status"></Column>
                <Column field="type" header="Type"></Column>
                <Column field="currency" header="Currency"></Column>
                <Column body={statusBodyTemplate9} header="Date"></Column>
              </DataTable>
              <Tooltip target=".export-buttons>button" position="bottom" />
              <Paginator
                first={basicFirst}
                rows={basicRows}
                totalRecords={tot}
                onPageChange={onBasicPageChange}
              ></Paginator>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Transact;
